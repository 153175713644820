var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Default" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDefault) + " ")]
          },
          proxy: true
        }
      ])
    },
    [_c("b-breadcrumb", { attrs: { items: _vm.items } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }