var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Separator" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSeparator) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "These breadcrumbs are only for demo puropses. You can change breadcrumb seprator by changing "
          )
        ]),
        _c("code", [_vm._v("$breadcrumb-divider")]),
        _c("span", [_vm._v(" variable value in scss")])
      ]),
      _c(
        "div",
        { attrs: { id: "component-breadcrumbs" } },
        [
          _c("b-breadcrumb", {
            staticClass: "breadcrumb-slash",
            attrs: { items: _vm.items }
          }),
          _c("b-breadcrumb", {
            staticClass: "breadcrumb-dots",
            attrs: { items: _vm.items }
          }),
          _c("b-breadcrumb", {
            staticClass: "breadcrumb-dashes",
            attrs: { items: _vm.items }
          }),
          _c("b-breadcrumb", {
            staticClass: "breadcrumb-pipes",
            attrs: { items: _vm.items }
          }),
          _c("b-breadcrumb", {
            staticClass: "breadcrumb-chevron mb-0",
            attrs: { items: _vm.items }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }