var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-code",
        {
          attrs: { title: "Alignment" },
          scopedSlots: _vm._u([
            {
              key: "code",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-card-text", [
            _c("span", [_vm._v("Use class ")]),
            _c("code", [_vm._v(".justify-content-{direction}")]),
            _c("span", [_vm._v(" to align breadcrumb to center.")])
          ]),
          _c("div", { attrs: { id: "breadcrumb-alignment" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-start breadcrumb-wrapper"
              },
              [_c("b-breadcrumb", { attrs: { items: _vm.items } })],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center breadcrumb-wrapper my-1"
              },
              [_c("b-breadcrumb", { attrs: { items: _vm.items } })],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-end breadcrumb-wrapper pr-1"
              },
              [_c("b-breadcrumb", { attrs: { items: _vm.items } })],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }