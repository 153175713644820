var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-code",
        {
          attrs: { title: "Slot" },
          scopedSlots: _vm._u([
            {
              key: "code",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.codeSlot) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-card-text", [
            _c("span", [_vm._v("You may also manually place individual ")]),
            _c("code", [_vm._v("<b-breadcrumb-item>")]),
            _c("span", [
              _vm._v(" child components in the default slot of the ")
            ]),
            _c("code", [_vm._v("<b-breadcrumb>")]),
            _c("span", [_vm._v(" component, as an alternative to using the ")]),
            _c("code", [_vm._v("items")]),
            _c("span", [
              _vm._v(
                " prop, for greater control over the content of each item:"
              )
            ])
          ]),
          _c(
            "b-breadcrumb",
            { staticClass: "breadcrumb-slash" },
            [
              _c("b-breadcrumb-item", { attrs: { href: "#home" } }, [
                _vm._v(" Home ")
              ]),
              _c("b-breadcrumb-item", { attrs: { href: "#foo" } }, [
                _vm._v(" Chat ")
              ]),
              _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                _vm._v(" Infos ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }